import React from "react";

import HTMLContainer from "components/HTMLContainer";

import styles from "./RichText.module.less";

/**
 * Used mostly to apply styling on remark's HTML.
 * Not needed when typo is aligned across the website
 *
 * @param {object} props
 * @param {React.ComponentProps<HTMLContainer>["html"]} props.html
 */
export default function RichText({ html }) {
  return <HTMLContainer html={html} className={styles.post} />;
}
