import React from "react";
import { graphql } from "gatsby";

import RichText from "components/RichText";
import Layout from "components/Layout";
import SEO, { createQueryImageFromFile } from "components/SEO";

import styles from "./styles.module.less";

const DATE_PUBLISHED = new Date("2019-08-12T12:51:31+00:00");

export const pageQuery = graphql`
  query SimplePageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        sharingImage {
          image {
            ...OpenGraphImage
            ...TwitterImage
            ...StructuredDataImage
          }
          alt
          caption
        }
      }
      fields {
        dateModified
      }
    }
  }
`;

export const SimplePageTemplate = ({ title, body }) => {
  return (
    <article className={styles.regularContainer}>
      <header>
        <h1>{title}</h1>
      </header>
      <RichText html={body} />
    </article>
  );
};

export default function SimplePage({
  location: { pathname },
  data: {
    markdownRemark: {
      frontmatter: { title: pageTitle, description, sharingImage },
      html,
      fields: { dateModified },
    },
  },
}) {
  const [
    openGraph,
    twitter,
    sdPrimaryImage,
  ] = createQueryImageFromFile(sharingImage, [
    "openGraphImage.resize",
    "twitterImage.resize",
    "sdImage.resize",
  ]);

  return (
    <Layout>
      <SEO
        pathname={pathname}
        title={pageTitle}
        description={description}
        images={{ openGraph, twitter, sdPrimaryImage }}
        datePublished={DATE_PUBLISHED}
        dateModified={new Date(dateModified)}
      />
      <SimplePageTemplate body={html} title={pageTitle} />
    </Layout>
  );
}
